//
//
//
//
//
//
//
//
//
//
//

import RequestTestForm from "./RequestTestForm";
export default {
  name: "SelectLocation",
  components: {
    RequestTestForm
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit");
    }
  }
};