//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectLocation from "./SelectLocation";
export default {
  name: "RequestTest",
  components: {
    SelectLocation
  },
  data: () => ({
    stepperItems: [{
      number: 1,
      title: "Select Location and Test Category",
      active: true
    }, {
      number: 2,
      title: "Select Service",
      active: false
    }, {
      number: 3,
      title: "Checkout & Payment",
      active: false
    }, {
      number: 4,
      title: "Summary/Instruction",
      active: false
    }]
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "customer-dashboard"
      });
    },
    toSelectService() {
      this.$router.push({
        name: "customer-request-test-service"
      });
    }
  }
};